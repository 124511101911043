// Highlighting for code blocks
import "prismjs/themes/prism.css"
import './static/style/gh.min.css'

export const onRouteUpdate = ({ location, prevLocation }) => {
    // console.log(".............. new pathname", location.pathname)
    // console.log(".............. old pathname", prevLocation ? prevLocation.pathname : null)    
}
  
export const onInitialClientRender = () => {
    // console.log(".... ReactDOM.render has executed")    
}