exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/test-page.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-templates-group-deals-js": () => import("./../../../src/templates/group-deals.js" /* webpackChunkName: "component---src-templates-group-deals-js" */),
  "component---src-templates-product-home-js": () => import("./../../../src/templates/product-home.js" /* webpackChunkName: "component---src-templates-product-home-js" */),
  "component---src-templates-product-review-js": () => import("./../../../src/templates/product-review.js" /* webpackChunkName: "component---src-templates-product-review-js" */),
  "component---src-templates-redirect-url-js": () => import("./../../../src/templates/redirect-url.js" /* webpackChunkName: "component---src-templates-redirect-url-js" */)
}

